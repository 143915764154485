import React, { useEffect, useState } from 'react';

/* Import Dashboard Mobile View */
import DashboardMobile from './DashboardMobile';
import isDesktop from '../../utils/isDesktop';

import styles from '../Dashboard/Dashboard.module.css';
import SidenavRight from '../Common/FilterSidenav/SidenavRight';
import Wrapper from '../WrapperDesktop/Wrapper';
import Analytics from '../Common/BusinessAnalytics/Analytics';
import CardBusiness from '../Common/Cards/CardBusiness';

/* Importing svg file as ReactComponent */
import { ReactComponent as NoOfQuestionsIcon } from '../../assets/DashboardIcons/NoOfQuestionsIcon.svg';
import { ReactComponent as AccuracyIcon } from '../../assets/DashboardIcons/AccuracyIcon.svg';
import { ReactComponent as NoOfAttemptsIcon } from '../../assets/DashboardIcons/NoOfAttemptsIcon.svg';
import { ReactComponent as TotalTimeIcon } from '../../assets/DashboardIcons/TotalTimeIcon.svg';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';


const Dashboard = () => {
  const institute_id = localStorage.getItem('institute_id');
  /* Get the user_id of the specific student */
  const { user_id } = useParams();
  console.log(user_id, " User ID in Student Profile");
  const isDesktopTrue = isDesktop();
  /* User Details */
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/usersection/${user_id}`)
      .then((response) => {
        setUserDetails(response.data);
        console.log("World ", response.data);
      })
  }, [user_id]);


  /* Cards Data */
  const [todayCardData, setTodayCardData] = useState({});
  const [lastActiveDayData, setLastActiveDayData] = useState({});
  const [studentListDashboard, setStudentListDashboard] = useState();
  console.log(user_id);
  
  useEffect(() => {
    // console.log(userDetails[0]?.skill_id, userDetails[0]?.stage_id, " Hello World");
    const fetchData = async () => {
      const resTodayCard = await axios.get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/todaycards/${user_id}`, {
        params: {
          skill_id: userDetails[0]?.skill_id,
          stage_id: userDetails[0]?.stage_id
        }
      }
      );
      const resLastActiveDayCard = await axios.get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/lastactivedaycards/${user_id}`
      );
      const resStudentListDashboard = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/studentlist/${institute_id}`);
      setStudentListDashboard(resStudentListDashboard.data);
      setTodayCardData(resTodayCard.data);
      setLastActiveDayData(resLastActiveDayCard.data);
    };
    // console.log("Hello World......");
    console.log({ ...todayCardData }, { ...lastActiveDayData });
    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, [user_id, userDetails]);

  const renderDaySpecificData = (day, description) => {
    if (day === 'Today') {
      if (todayCardData[0] === undefined) return;
      if (description === 'No. of Attempts') return todayCardData[0]?.No_of_Attempts ?? '00';
      else if (description === 'No. of Questions') return todayCardData[0]?.No_of_Questions ?? '00';
      else if (description === 'Total Time') {
        let tempTime;
        try {
          tempTime = todayCardData[0].Total_time.split(':');
          tempTime = tempTime[0] + 'h' + ' ' + tempTime[1] + 'm' + ' ' + tempTime[2] + 's';
          return tempTime;
        } catch (error) {
          tempTime = '00h 00m 00s';
          console.error(error);
        }
      }
      else if (description === 'Accuracy') return (todayCardData[0]?.Accuracy ?? '0') + '%';
    } else if (day === 'Last Active Day') {
      if (lastActiveDayData[0] === undefined) return;
      if (description === 'No. of Attempts') return lastActiveDayData[0]?.No_of_Attempts ?? '00';
      else if (description === 'No. of Questions') return lastActiveDayData[0]?.No_of_Questions ?? '00';
      else if (description === 'Total Time') {
        let tempTime;
        try {
          tempTime = lastActiveDayData[0]?.Total_time.split(':');
          tempTime = tempTime[0] + 'h' + ' ' + tempTime[1] + 'm' + ' ' + tempTime[2] + 's';
        } catch (error) {
          tempTime = '00h 00m 00s'
        }
        return tempTime;
      }
      else if (description === 'Accuracy') return (lastActiveDayData[0]?.Accuracy ?? '0') + '%';
    }
  }

  /* Analytics */



  return (
    <>{
      isDesktopTrue ?
        (
          <DashboardMobile userDetails={userDetails} />
        ) :
        (
          <>
            <Wrapper />
            {/* UserDetails */}
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '2.564vw 24.7vw 3.333vw 8.462vw', alignItems: 'center' }}>
              <div style={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '1.5rem', lineHeight: '1.758rem' }}>{userDetails[0]?.name}</div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{
                  border: '2px solid #313C63',
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: '3.846vw',
                  borderRadius: '2px',
                  width: '22.5vw',
                  height: '2.564vw',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontSize: '1rem',
                  fontWeight: '400',
                  marginRight: '0.769vw',
                }}>
                  <p>{userDetails[0]?.skill_name}</p>
                  <p>{userDetails[0]?.stage_name}</p>
                  <p>{userDetails[0]?.percent_skill_completion}%</p>
                </div>
                {/* <Link to={"/timeline"}> */}
                <Link className={styles.buttonStyle} to={`/timeline/${user_id}`}>
                  <span style={{fontSize: '0.875rem'}}>Timeline</span>
                  <svg className={styles.svgStyle} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99947 2.76704e-06C9.31212 -0.00314381 11.9968 2.67774 12.0024 5.99423C12.008 9.31072 9.31666 12.0042 5.99947 12C4.40831 12 2.88232 11.3679 1.7572 10.2428C0.632085 9.11767 0 7.59168 0 6.00052C0 4.40936 0.632085 2.88338 1.7572 1.75826C2.88232 0.633137 4.40831 0.00105165 5.99947 0.00105165V2.76704e-06ZM5.99947 0.837342C4.97878 0.837688 3.9811 1.14061 3.13253 1.70783C2.28396 2.27505 1.62258 3.08109 1.232 4.02409C0.841414 4.9671 0.739151 6.00472 0.938134 7.00583C1.13712 8.00694 1.62841 8.92659 2.34993 9.64854C3.07145 10.3705 3.9908 10.8624 4.99178 11.0619C5.99277 11.2615 7.03046 11.1599 7.9737 10.7699C8.91694 10.3799 9.72339 9.71901 10.2911 8.87078C10.8589 8.02255 11.1624 7.02506 11.1634 6.00437C11.1645 5.32588 11.0317 4.65383 10.7727 4.02675C10.5136 3.39966 10.1333 2.82986 9.65366 2.35002C9.17398 1.87017 8.60431 1.48971 7.97731 1.23043C7.35031 0.971157 6.67831 0.838169 5.99982 0.83909L5.99947 0.837342Z" fill="white" />
                    <path d="M6.42062 7.11934C6.42062 7.65985 6.42062 8.20048 6.42062 8.74122C6.42062 9.01847 6.24931 9.21041 6.00353 9.21146C5.75775 9.21251 5.58398 9.01812 5.58398 8.74367C5.58398 7.65775 5.58398 6.57195 5.58398 5.48626C5.58398 5.20901 5.75565 5.02407 6.00737 5.02651C6.25211 5.02896 6.42027 5.21426 6.42062 5.48417C6.42156 6.02911 6.42156 6.57416 6.42062 7.11934Z" fill="white" />
                    <path d="M6.55668 3.76436C6.5564 3.874 6.52367 3.98111 6.4626 4.07218C6.40153 4.16324 6.31487 4.23418 6.21354 4.27606C6.11221 4.31794 6.00075 4.32888 5.89321 4.30749C5.78567 4.28611 5.68687 4.23337 5.60927 4.15591C5.53167 4.07845 5.47874 3.97975 5.45715 3.87226C5.43557 3.76476 5.4463 3.65328 5.48798 3.55187C5.52967 3.45046 5.60045 3.36366 5.6914 3.30243C5.78235 3.24119 5.88939 3.20825 5.99904 3.20777C6.07226 3.20749 6.14481 3.22169 6.21252 3.24955C6.28023 3.27741 6.34177 3.31838 6.39359 3.37011C6.44542 3.42184 6.4865 3.4833 6.51449 3.55096C6.54248 3.61862 6.55682 3.69114 6.55668 3.76436Z" fill="white" />
                  </svg>
                  <div className={styles.tooltip}>Check History</div>
                </Link>
                {/* </Link> */}
              </div>
            </div>

            {/* Display Grid: Cards + Analytics */}
            <div className={styles.displayGrid}>
              <div className={styles.container}>
                <p className={styles.dayTime}>Last Active Day</p>
                <div className={styles.cardsGrid}>
                  <CardBusiness day='Last Active Day' description="No. of Attempts" tooltipMessage="No. of Attempts per day" value={lastActiveDayData[0]?.No_of_Attempts} Image={NoOfAttemptsIcon} />
                  <CardBusiness day='Last Active Day' description="No. of Questions" tooltipMessage="No. of Questions solved" value={lastActiveDayData[0]?.total_questions} Image={NoOfQuestionsIcon} />
                  <CardBusiness day='Last Active Day' description="Total Time" tooltipMessage="Total Time" value={renderDaySpecificData('Last Active Day', "Total Time")} Image={TotalTimeIcon} />
                  <CardBusiness day='Last Active Day' description="Accuracy" tooltipMessage="% of correct questions solved" value={renderDaySpecificData('Last Active Day', "Accuracy")} Image={AccuracyIcon} />
                </div>
              </div>
              <div className={styles.container}>
                <p className={styles.dayTime}>Today</p>
                <div className={styles.cardsGrid}>
                  <CardBusiness day='Today' description="No. of Attempts" tooltipMessage="No. of Attempts per day" value={todayCardData[0]?.No_of_Attempts} Image={NoOfAttemptsIcon} />
                  <CardBusiness day='Today' description="No. of Questions" tooltipMessage="No. of Questions solved" value={todayCardData[0]?.total_questions} Image={NoOfQuestionsIcon} />
                  <CardBusiness day='Today' description="Total Time" tooltipMessage="Total Time" value={renderDaySpecificData('Today', "Total Time")} Image={TotalTimeIcon} />
                  <CardBusiness day='Today' description="Accuracy" tooltipMessage="% of correct questions solved" value={renderDaySpecificData('Today', "Accuracy")} Image={AccuracyIcon} />
                </div>
              </div>

              {/* Two Tables for Analytics */}
              <Analytics day='Last Active Day' user_id={user_id} />
              <Analytics day='Today' user_id={user_id} />
            </div>

            {/* SideNav Right */}
            <SidenavRight studentsList={studentListDashboard} />
          </>
        )
    }

    </>
  )
}

export default Dashboard;