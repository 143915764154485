import React from "react";
import styles from "./ChangePassword.module.css";
import { Grid, TextField } from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "@mui/base";
import { useState } from "react";
import axios from "axios";
import SmartsaIcon from "../../assets/auth-icons/smartsa_icon.svg"
import { Navigate } from "react-router-dom";
import {InputAdornment} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {IconButton} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {Dialog, DialogContentText, DialogContent} from "@mui/material";

const ChangePasswordDesktop = ({instituteId, page, setShowChangePassword}) => {
const [newPassword, setNewPassword] = useState();
const [confirmPassword, setConfirmPassword] = useState();
const [message, setMessage] = useState()
const [changePasswordMessage, setChangePasswordMessage] = useState();
const [openDialog, setOpenDialog] = useState(false);
const navigate = useNavigate()

const [showPassword, setShowPassword] = useState(false);  
const [showConfirmPassword, setShowConfirmPassword] = useState(false);

const handleClickShowPassword = () => setShowPassword((show) => !show);
const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};
console.log(page)

const handleSubmit = () => {
  if(newPassword != confirmPassword) {
    // console.log('hello')
    setMessage("Passwords Don't Match")
  }else {
    setMessage("")
    axios
    .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/change-password/reset-password/${instituteId}`, {
      newPassword: newPassword,
    })
    .then((response) => {
      console.log("Password Changed Succesfully", response.data);
      setOpenDialog(true)
      setTimeout(() => {
        if (page == "reset") {
          navigate("/login");
        } else if (page == "forgot") {
          navigate("/login");
        } else if (page=="login") {
          setShowChangePassword(2);
        }
      }, 3000);
    })
    .catch((error) => {
      console.error("Error validating OTP:", error);
      setChangePasswordMessage(error.response.data.message);
    });
  }
}

const handleDialogClose = () => {
  setOpenDialog(false);
};

console.log(newPassword, confirmPassword)
  return (
    <Container className={styles.changeContainer} style={{ maxWidth: "31.25vw" }}>
      <div>
        <img
          src={SmartsaIcon}
          style={{ width: "3.125rem", height: "5rem" }}
          alt="Smartsa Logo"
        />
      </div>
      <div className={styles.pageHeader}>Change Password</div>
      <Grid container spacing={0.75}>
        <Grid item xs={12}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              className={styles.inputFields}
              required
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-confirm-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              className={styles.inputFields}
              required
              id="outlined-adornment-confirm-password"
              type={showConfirmPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              />
          </FormControl>
        </Grid>
      </Grid>
      <div className={styles.buttonContainer}>
      <Button className={styles.cancelButton} href="/edit-profile">Cancel</Button>
        <Button className={styles.submitButton} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      <p style={{ color: "red", textAlign: "center" }}>
      {message}
      {changePasswordMessage}
      </p>
      <Dialog open={openDialog} onClose={handleDialogClose} sx={{ '& .MuiPaper-root': {background: '#fff',border: '3px solid #333333'}}}>
        <DialogContent>
          <DialogContentText style={{color:"green"}}>Your password has been changed successfully.</DialogContentText>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
export default ChangePasswordDesktop;