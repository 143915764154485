import { animate, useAnimation, useInView, useMotionValue, useTransform } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styles from "./AboutUs.module.css"
import SmartsaS from "../Characters/SmartsaS";
import SmartsaM from "../Characters/SmartsaM";
import SmartsaA1 from "../Characters/SmartsaA1";
import SmartsaR from "../Characters/SmartsaR";
import SmartsaT from "../Characters/SmartsaT";
import SmartsaS2 from "../Characters/SmartsaS2";
import SmartsaA2 from "../Characters/SmartsaA2";
import { motion } from "framer-motion";
import cofounderImage1 from "../../assets/animation-icons/cofounder-image-1.png"
import cofounderImage2 from "../../assets/animation-icons/cofounder-image-2.png"
import Navbar from "../Common/NavBars/Navbar";
import UnAuthNav from "../Common/NavBars/UnAuthNav";

const AboutUs = () => {
    const smartsaref = useRef(null);
    const statisticsRef = useRef(null);
    const teamRef1 = useRef(null);
    const teamRef2 = useRef(null);
    const token = localStorage.getItem("token");
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [isExpanded, setIsExpanded] = useState(false);
    const Sprintcount = useMotionValue(0);
    const roundedSprint = useTransform(Sprintcount, Math.round);
    const coinsCount = useMotionValue(0);
    const roundedCoin = useTransform(coinsCount, Math.round);
    const Solvedcount = useMotionValue(0);
    const roundedSolved = useTransform(Solvedcount, Math.round);
    const Timecount = useMotionValue(0);
    const roundedTime = useTransform(Timecount, Math.round);

    const isInView = useInView(smartsaref, { once: true });
    const statisticsIsInView = useInView(statisticsRef, { once: true });
    const teamIsInView1 = useInView(teamRef1, { once: true });
    const teamIsInView2 = useInView(teamRef2, { once: true });

    const animateS1Down = useAnimation();
    const animateS1Opacity = useAnimation();
    const animateMDown = useAnimation();
    const animateMOpacity = useAnimation();
    const animateA1Down = useAnimation();
    const animateA1Opacity = useAnimation();
    const animateRDown = useAnimation();
    const animateROpacity = useAnimation();
    const animateTDown = useAnimation();
    const animateTOpacity = useAnimation();
    const animateS2Down = useAnimation();
    const animateS2Opacity = useAnimation();
    const animateA2Down = useAnimation();
    const animateA2Opacity = useAnimation();
    const textAnimation1 = useAnimation();
    const textAnimation2 = useAnimation();
    const photoAnimation = useAnimation();
    const textAnimation3 = useAnimation();
    const textAnimation21 = useAnimation();
    const textAnimation22 = useAnimation();
    const photoAnimation2 = useAnimation();
    const textAnimation23 = useAnimation();

    const teamAnimation = async () => {
        textAnimation1.start({
            y: [0, -30],
            opacity: [1, 0],
            transition: {
                duration: 0.5,
                ease: "linear",
                repeat: 1,
                repeatType: "mirror",
                repeatDelay: 5,
            },
        });
        textAnimation2.start({
            y: [0, 30],
            opacity: [1, 0],
            transition: {
                duration: 0.5,
                ease: "linear",
                repeat: 1,
                repeatType: "mirror",
                repeatDelay: 5,
            },
        });
        photoAnimation.start({
            x: ["0%", "-210%"],
            rotate: [0, -7.5],
            transition: {
                duration: 0.5,
                ease: "linear",
                repeat: 1,
                repeatType: "mirror",
                repeatDelay: 5,
            },
        });
        textAnimation3.start({
            opacity: [0, 1],
            transition: {
                delay: 0.1,
                duration: 0.5,
                ease: "linear",
                repeat: 1,
                repeatType: "mirror",
                repeatDelay: 5,
            },
        });
    };

    const teamAnimation2 = async () => {
        textAnimation21.start({
            y: [0, -30],
            opacity: [1, 0],
            transition: {
                duration: 0.5,
                ease: "linear",
                repeat: 1,
                repeatType: "mirror",
                repeatDelay: 5,
            },
        });

        textAnimation22.start({
            y: [0, 30],
            opacity: [1, 0],
            transition: {
                duration: 0.5,
                ease: "linear",
                repeat: 1,
                repeatType: "mirror",
                repeatDelay: 5,
            },
        });
        photoAnimation2.start({
            x: ["0%", "-210%"],
            rotate: [0, -7.5],
            transition: {
                duration: 0.5,
                ease: "linear",
                repeat: 1,
                repeatType: "mirror",
                repeatDelay: 5,
            },
        });
        textAnimation23.start({
            opacity: [0, 1],
            transition: {
                delay: 0.1,
                duration: 0.5,
                ease: "linear",
                repeat: 1,
                repeatType: "mirror",
                repeatDelay: 5,
            },
        });
    };

    useEffect(() => {
        if (token) {
          setIsLoggedIn(true);
        }
      }, [token]);

    useEffect(() => {
        let interv, intv2, time1, time2;
    
        if (teamIsInView1) {
          time1 = setTimeout(() => {
            teamAnimation();
          }, 1000);
          interv = setInterval(() => {
            teamAnimation();
          }, 8000);
        }
    
        if (teamIsInView2) {
          time2 = setTimeout(() => {
            teamAnimation2();
          }, 2000);
          intv2 = setInterval(() => {
            teamAnimation2();
          }, 9000);
        }
    
        return () => {
          clearTimeout(time1);
          clearTimeout(time2);
          clearInterval(interv);
          clearInterval(intv2);
          textAnimation1.stop();
          textAnimation2.stop();
          textAnimation3.stop();
          photoAnimation2.stop();
          textAnimation21.stop();
          textAnimation22.stop();
          textAnimation23.stop();
          photoAnimation.stop();
        };
      }, [teamIsInView1, teamIsInView2]);



    useEffect(() => {
        let sprintControls, coinsControls, solvedControls, timeControls;

        if (statisticsIsInView) {
            sprintControls = animate(Sprintcount, 40, { duration: 5 });
            coinsControls = animate(coinsCount, 46, { duration: 5 });
            solvedControls = animate(Solvedcount, 38, { duration: 5 });
            timeControls = animate(Timecount, 30, { duration: 5 });
        }
        return () => {
            sprintControls?.stop();
            coinsControls?.stop();
            solvedControls?.stop();
            timeControls?.stop();
        };
    }, [statisticsIsInView]);

    const startS1Animation = async () => {
        console.log("Hello");

        try {
            animateS1Down.start({
                y: [30, -90],
                scale: [1.2, 1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    repeat: Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
            await animateS1Opacity.start({
                y: [50, -90],
                opacity: [0, 1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    repeat: Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
        } catch (error) {
            
        }
       
    };

    const startMAnimation = async () => {
        try {
            animateMDown.start({
                y: [-57, 80],
                // scale:[1.2,1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    //  repeat:Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
            await animateMOpacity.start({
                y: [-50, 90],
                opacity: [0, 1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    //  repeat:Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
        } catch (error) { }
    };

    const startA1Animation = async () => {
        try {
            animateA1Down.start({
                y: [30, -90],
                // scale:[1.2,1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    repeat: Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
            await animateA1Opacity.start({
                y: [50, -90],
                opacity: [0, 1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    repeat: Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
        } catch (error) {
            // console.log(error);
        }
    };

    const startRAnimation = async () => {
        try {
            animateRDown.start({
                y: [-57, 80],
                // scale:[1.2,1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    //  repeat:Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
            await animateROpacity.start({
                y: [-50, 90],
                opacity: [0, 1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    //  repeat:Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
        } catch (error) {
            // console.log(error);
        }
    };

    const startTAnimation = async () => {
        try {
            animateTDown.start({
                y: [30, -90],
                // scale:[1.2,1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    repeat: Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
            await animateTOpacity.start({
                y: [50, -90],
                opacity: [0, 1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    repeat: Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
        } catch (error) {
            // console.log(error);
        }
    };

    const startS2Animation = async () => {
        try {
            animateS2Down.start({
                y: [-57, 80],
                // scale:[1.2,1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    //  repeat:Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
            await animateS2Opacity.start({
                y: [-50, 90],
                opacity: [0, 1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    //  repeat:Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
        } catch (error) {
            // console.log(error);
        }
    };

    const startA2Animation = async () => {
        try {
            animateA2Down.start({
                y: [30, -90],
                // scale:[1.2,1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    repeat: Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
            await animateA2Opacity.start({
                y: [50, -90],
                opacity: [0, 1],
                transition: {
                    duration: 0.4,
                    ease: "linear",
                    repeat: Infinity,
                    repeat: 1,
                    repeatType: "mirror",
                    repeatDelay: 0.3,
                },
            });
        } catch (error) {
            // console.log(error);
        }
    };

    const allAnimations = async () => {
        await startS1Animation();
        await startMAnimation();
        await startA1Animation();
        await startRAnimation();
        await startTAnimation();
        await startS2Animation();
        await startA2Animation();
    };

    useEffect(() => {
        let intvl;
        if (isInView) {
            setTimeout(() => {
                try {
                    allAnimations();
                } catch (error) {
                    console.log(error);
                }
            }, 1500);

            intvl = setInterval(() => {
                try {
                    allAnimations();
                } catch (error) {
                    console.log(error);
                }
            }, 11500);
        }

        return () => {
            clearInterval(intvl);
            animateS1Down.stop();
            animateS1Opacity.stop();
            animateMDown.stop();
            animateMOpacity.stop();
            animateA1Down.stop();
            animateA1Opacity.stop();
            animateRDown.stop();
            animateROpacity.stop();
            animateTDown.stop();
            animateTOpacity.stop();
            animateS2Down.stop();
            animateS2Opacity.stop();
            animateA2Down.stop();
            animateA2Opacity.stop();
        };
    }, [isInView]);


    return (
        <div id="about-us">
            {isLoggedIn ? (
                <Navbar/>
            ) : (
                <UnAuthNav />
            )}
            <div className={styles.nameAnimationContainer}
                ref={smartsaref}
            >
                <div className={styles.svgContainer}>
                    <SmartsaS
                        className={styles.svgElement}
                        animateDown={animateS1Down}
                        animateOpacity={animateS1Opacity}
                    />
                </div>
                <div className={styles.svgContainer}>
                    <SmartsaM
                        className={styles.svgElement}
                        animateDown={animateMDown}
                        animateOpacity={animateMOpacity}
                    />
                </div>
                <div className={styles.svgContainer}>
                    <SmartsaA1
                        className={styles.svgElement}
                        animateDown={animateA1Down}
                        animateOpacity={animateA1Opacity}
                    />
                </div>
                <div className={styles.svgContainer}>
                    <SmartsaR
                        className={styles.svgElement}
                        animateDown={animateRDown}
                        animateOpacity={animateROpacity}
                    />
                </div>
                <div className={styles.svgContainer}>
                    <SmartsaT
                        className={styles.svgElement}
                        animateDown={animateTDown}
                        animateOpacity={animateTOpacity}
                    />
                </div>
                <div className={styles.svgContainer}>
                    <SmartsaS2
                        className={styles.svgElement}
                        animateDown={animateS2Down}
                        animateOpacity={animateS2Opacity}
                    />
                </div>
                <div className={styles.svgContainer}>
                    <SmartsaA2
                        className={styles.svgElement}
                        animateDown={animateA2Down}
                        animateOpacity={animateA2Opacity}
                    />
                </div>
            </div>
            <motion.div
                className={styles.ourStoryContainer}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
            >
                <div>
                    <h2>Our Story</h2>
                </div>
                <div>
                    <p>
                        It sounds “the same old way” and believe us it is. As we went
                        to various schools across the country to host a basic test
                        among the secondary level students, we found a common problem
                        – the problem was common but over-looked by everyone.{" "}
                        {!isExpanded && (
                            <span
                                className={styles.readMore}
                                onClick={() => {
                                    setIsExpanded((prev) => !prev);
                                }}
                            >
                                Read More
                            </span>
                        )}
                    </p>
                    {isExpanded && (
                        <>
                            <p>
                                Hundreds of students who appeared for the test failed to
                                solve it. Reason being difficulty in understanding basic
                                concepts of Mathematics. Mathematics lays the foundation
                                of “Cognitive Flexibility” or “Task Switching Ability” in
                                teenagers. It is a quality prevalent in geniuses of all
                                fields.
                            </p>
                            <p>
                                SMARTSA conducted test for more than 3000 students. More
                                than 80% have at least one of the problems (speed,
                                accuracy or backlogs). Also, according to UNESCO SDG 4
                                report, only 12.3% of Indians aged 10-16 years are
                                proficient in basic math.
                            </p>
                            <p>
                                We found it quite interesting and it became our
                                responsibility, impulsively. We decided to work upon a
                                platform to help the younger generation of our country in
                                recognizing and improving their shortcomings. As we
                                progressed in providing this support stage, we were
                                determined to pave the path for the geniuses who hesitate
                                to come out to the light, with SMARTSA.{" "}
                                <span
                                    className={styles.readMore}
                                    onClick={() => {
                                        setIsExpanded((prev) => !prev);
                                    }}
                                >
                                    Read Less
                                </span>
                            </p>
                        </>
                    )}
                </div>
            </motion.div>
            <div ref={statisticsRef} className={styles.statsiticContainer}>
                <div className={styles.statisticDataContainer}>
                    <h1>
                        <motion.span>{roundedSprint}</motion.span>k+
                    </h1>
                    <h4>Sprints Completed</h4>
                </div>
                <div className={styles.statisticDataContainer}>
                    <h1>
                        <motion.span>{roundedCoin}</motion.span>k+
                    </h1>
                    <h4>Coins Earned</h4>
                </div>
                <div className={styles.statisticDataContainer}>
                    <h1>
                        <motion.span>{roundedSolved}</motion.span>k+
                    </h1>
                    <h4>Questions Solved</h4>
                </div>
                <div className={styles.statisticDataContainer}>
                    <h1>
                        <motion.span>{roundedTime}</motion.span>k+
                    </h1>
                    <h4>Practice Time (min.)</h4>
                </div>
            </div>
            <motion.div className={styles.meettheteam}>
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                    <h1 style={{ fontSize: "3.718vw", fontWeight: "bold" }}>
                        Meet The Team
                    </h1>
                </motion.div>
                <motion.div
                    viewport={{ once: true }}
                    ref={teamRef1}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    className={styles.cfContainer}
                >
                    <div className={styles.cfNameContainer}>
                        <h1>
                            <motion.span
                                style={{ display: "inline-block" }}
                                animate={textAnimation1}
                                initial={{ y: 0 }}
                            >
                                Nikhil
                            </motion.span>
                            <br />
                            <motion.span
                                style={{ display: "inline-block" }}
                                animate={textAnimation2}
                            >
                                Bhandari
                            </motion.span>
                        </h1>
                    </div>
                    <motion.img
                        animate={photoAnimation}
                        className={styles.cfImage}
                        src={cofounderImage1}
                        alt="cofounder1"
                    />
                    <motion.div
                        animate={textAnimation3}
                        initial={{ opacity: 0 }}
                        className={styles.textContainer}
                    >
                        <div className={styles.headingContainer}>
                            <h1>Co-Founder</h1>
                        </div>
                        <div className={styles.subheadingContainer}>
                            <h1>
                                Nikhil, has pursued Integrated MSc. in Applied Mathematics
                                from IIT Roorkee (IIT JEE 2009).
                            </h1>
                        </div>
                        <div className={styles.contentContainer}>
                            <p>
                                He is an accomplished data scientist with over 10 years of
                                experience in Data sciences across finance, automotive,
                                education, and logistics industry with a proven track
                                record in spearheading transformative data-driven
                                initiatives.
                            </p>
                            <p>
                                He brings a wealth of research experience from his time at
                                the Indian School of Business and RWTH Aachen University
                                in Germany. He established SMARTSA with the insight that
                                tailored math education is essential for individuals of
                                all ages and diverse learning capacities.
                            </p>
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div
                    ref={teamRef2}
                    initial={{ opacity: 0 }}
                    viewport={{ once: true }}
                    whileInView={{ opacity: 1 }}
                    style={{ marginTop: "20vw" }}
                    className={styles.cfContainer}
                >
                    <div className={styles.cfNameContainer}>
                        <h1>
                            <motion.span
                                style={{ display: "inline-block" }}
                                animate={textAnimation21}
                                initial={{ y: 0 }}
                            >
                                Kunal
                            </motion.span>
                            <br />
                            <motion.span
                                style={{ display: "inline-block" }}
                                animate={textAnimation22}
                            >
                                Bhandari
                            </motion.span>
                        </h1>
                    </div>
                    <motion.img
                        animate={photoAnimation2}
                        className={styles.cfImage}
                        src={cofounderImage2}
                        alt="cofounder1"
                    />
                    <motion.div
                        animate={textAnimation23}
                        initial={{ opacity: 0 }}
                        className={styles.textContainer}
                    >
                        <div className={styles.headingContainer}>
                            <h1>Co-Founder</h1>
                        </div>
                        <div className={styles.subheadingContainer}>
                            <h1>
                                Kunal, holds a degree in Mechanical Engineering from Pune
                                University.
                            </h1>
                        </div>
                        <div className={styles.contentContainer}>
                            <p>
                                With a robust background in founding and nurturing
                                early-stage startups, he is adept at building
                                organizations from scratch.
                            </p>
                            <p>
                                Additionally, he boasts over 8 years of experience as an
                                educator, specializing in teaching Mathematics and Physics
                                to both school and engineering students.
                            </p>
                            <p>
                                Kunal's expertise lies in enhancing core math
                                fundamentals, time management, and strategic planning to
                                help students excel in competitive exams such as ISI, IIT
                                JEE, NEET, NDA, and GATE. Known for his innovative
                                curriculum and teaching methodologies, he focuses on
                                clearing backlogs, improving speed, and fostering accuracy
                                in quantitative skills.
                            </p>
                        </div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </div>
    )
}

export default AboutUs;