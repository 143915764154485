import React from 'react';
import styles from './SidenavLeft.module.css';
import {ReactComponent as SmartsaHatColorIcon} from '../../../assets/DashboardIcons/SmartsaHatColorIcon.svg';
import {ReactComponent as SmartsaAnalyticsIcon} from '../../../assets/DashboardIcons/AnalyticsColorIcon.svg';
import { Link } from 'react-router-dom';

const SidenavLeft = ({isActivePage}) => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarLink}>
        <Link to={'/dashboard'}>
        <SmartsaHatColorIcon className={`${isActivePage === 'dashboard' ? styles.sidebarNavIconStyle : ""}`} />
        </Link>
      </div>
      <div className={styles.sidebarLink}>
      <Link to={'/analytics'}>
        <SmartsaAnalyticsIcon className={`${isActivePage === 'analytics' ? styles.sidebarNavIconStyle : ""}`} />
        </Link>
        </div>
    </div>
  )
}

export default SidenavLeft;